window.addEventListener('message', (e: MessageEvent) => {
	let data: { context?: string; src?: string; height?: string };

	try {
		data = JSON.parse(e.data);
	} catch (error) {
		return false;
	}

	if (data.context !== 'iframe.resize') {
		return false;
	}

	const iframe = document.querySelector<HTMLIFrameElement>(`iframe[src="${data.src}"]`);

	if (!iframe) {
		return false;
	}

	iframe.height = data.height ?? iframe.height;

	return;
});