﻿export function HoverObserver() {

	var elements = document.querySelectorAll('[data-hover-class]');

	for (var i = 0; i < elements.length; i++) {
		const element = elements[i];

		if (element.getAttribute('data-hover-observer') != 'true') {
			element.setAttribute('data-hover-observer', 'true');

			var className = element.getAttribute("data-hover-class") as string;

			element.addEventListener('mouseenter', (e) => {
				element.classList.add(className);
			});

			element.addEventListener('mouseleave', (e) => {
				element.classList.remove(className);
			});
		}
	}
}