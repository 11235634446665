﻿export function setHash(hash: string) {
	if (!hash.startsWith('#')) {
		hash = `#${hash}`;
	}

	const newUrl = `${window.location.pathname}${window.location.search}${hash}`;
	history.replaceState(null, '', newUrl);
	//history.pushState(null, '', newUrl);
}

export function getHash() {
	return document.location.hash;
}